import { Outlet, useParams } from "react-router-dom";
import { useStore, useStoreActions, useStoreState } from "easy-peasy";
import {useEffect} from "react";

const Layout = () => {
  const { shopId } = useParams();
  const { setShopId } = useStoreActions((actions) => actions.shop);
  const storedShopId = useStoreState((state) => state.shop.shopId);
  const getMenu = useStoreActions(actions => actions.menu.getMenu);
  const getServiceFees = useStoreActions(actions => actions.shop.getServiceFees);
  const { setMenu } = useStoreActions((actions) => actions.menu);
  const { clearCart } = useStoreActions((actions) => actions.cart);
  const { menu } = useStoreState(state => state.menu);
  const store = useStore();

  useEffect(() => {
    if(storedShopId !== shopId) {
      setShopId(null);
      store.persist.clear().then((result) => {
        setShopId(shopId);
        setMenu(null);
        clearCart();
        getMenu(shopId);
        getServiceFees();
      });
    } else if(menu === null || menu === undefined) {
      getMenu(shopId);
    }
  }, [shopId]);
  return <Outlet />;
};

export default Layout;

import * as yup from "yup";
import { phoneRegExp } from "./utilities";

const checkoutSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email address is required"),
    emailConfirmation: yup
      .string()
      .oneOf([yup.ref("email"), null], "Emails must match"),
    phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    deliveryOrPickup: yup.number().notRequired(),
    nameOnCard: yup.string().required("Card name is required"),
    cardNumber: yup.object().required("Card number is required"),
    cardExpiry: yup.object().required("Card expiration is required"),
    cardCvc: yup.object().required("Card security code is required"),
      tableNumber: yup.string().nullable(),
    tip: yup
      .number()
      .required("Please enter a valid tip value")
      .min(0, "Please enter a valid amount"),
    specialInstructions: yup.string()
  })
  .required();

export default checkoutSchema;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import Card from "@matter/common/Card";
import HeroTitle from "@matter/common/HeroTitle";
import ButtonForCard from "./ButtonForCard";

const DeliveryOrPickupCard = ({ buttons }) => {
  const { setValue } = useFormContext();

  const [option, setOption] = useState(0);

  useEffect(() => {
    if (option) {
      setValue("deliveryOrPickup", option);
    }
  }, [option]);

  return (
    <Card styles="container w-100 md:w-[630px] flex flex-col gap-4 p-4">
      <HeroTitle styles="text-lg place-self-start">Pickup / Delivery</HeroTitle>
      <div className="flex flex-col md:flex-row gap-2 justify-between">
        {buttons.map((b, index) => (
          <ButtonForCard
            key={`${b}-${index}`}
            onClick={() => setOption(index)}
            label={b.label}
            selectedValue={option}
            index={index}
            value={b.label}
            requireTableNumber={b.requireTableNumber}
          />
        ))}
      </div>
    </Card>
  );
};

DeliveryOrPickupCard.propTypes = {
  buttons: PropTypes.array.isRequired
};

export default DeliveryOrPickupCard;

import PropTypes from "prop-types";
import {Element} from "react-scroll";
import HeroTitle from "@matter/common/HeroTitle";
import MenuItem from "@matter/components/menu/MenuItem";


const MenuCategory = ({ category, name }) => {
  return (
    <Element className={"mt-[40px] scroll-mt-[60px]"} name={name} id={`${name}`}>
        <div className={"mb-[16px]"}>
          <HeroTitle styles="text-[30px] place-self-start mb-4 font-[500] leading-[50px]">
            {name}
          </HeroTitle>
        </div>
      <div className={"grid grid-cols-2 xlg:grid-cols-3 2x:grid-cols-4 gap-[15px]"}>
        {category.items.map((item) => (
          <MenuItem key={item.menuItemId} item={item} category={name} />
        ))}
      </div>
    </Element>
  );
};

MenuCategory.propTypes = {
  category: PropTypes.object,
  name: PropTypes.string
};

export default MenuCategory;

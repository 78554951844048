import PropTypes from "prop-types";
import classNames from "classnames";

const Button = ({ children, styles, ...rest }) => {
  const classes = classNames(
    {
      [`${styles}`]: !!styles
    },
    "bg-matter-coral disabled:bg-matter-gray-light cursor-pointer disabled:cursor-not-allowed text-white font-semibold flex justify-center p-1 items-center place-content-center hover:opacity-50 rounded-[3px] border-none"
  );

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.string,
  rest: PropTypes.any
};

Button.defaultProps = {
  styles: ""
};

export default Button;

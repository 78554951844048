import {action, thunk} from "easy-peasy";
import { assign } from "lodash";
import axios from "axios";
import {baseUrl} from "@matter/baseUrl";

const orderModel = {
    order: null,
    setOrder: action((state, data) => {
        state.order = data;
    }),
    getOrder: thunk(async (actions, orderId) => {
        axios.get(`${baseUrl}/get_order_details?orderId=${orderId}`)
            .then(result => {
                if(result.status === 200) {
                    actions.setOrder(result.data);
                }
            })
            .catch(error => console.log(error));
    }),
}

export default orderModel;

import {action, thunk} from "easy-peasy";
import axios from "axios";
import {baseUrl} from "@matter/baseUrl";

const menuModel = {
    menu: null,
    setMenu: action((state, data) => {
        state.menu = data;
    }),
    getMenu: thunk(async (actions, shopId) => {
        axios.get(`${baseUrl}/get_menu_v3?shop_id=${shopId}`, {headers: {"Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "Expires": "0"}})
            .then(result => {
                if(result.status === 200) {
                    actions.setMenu(result.data);
                }
            })
    }),
}

export default menuModel;

import PropTypes from "prop-types";

const CostItem = ({ label, value }) => {
  return (
    <div className={"flex  flex-row justify-between first:mt-4"}>
      <span className={`${label === "Subtotal" ? "text-[13px] font-[500]" : "text-sm" } capitalize`}>{label}</span>
      <span className={`${label === "Subtotal" ? "text-[13px] font-[500]" : "text-sm"}`}>${Number(value).toFixed(2)}</span>
    </div>
  );
};

CostItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number
};

export default CostItem;

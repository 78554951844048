import { useFormContext } from "react-hook-form";
import TextAreaInput from "@matter/common/Form/TextAreaInput";
import HeroTitle from "@matter/common/HeroTitle";
import Card from "@matter/common/Card";

const SpecialInstructionsCard = () => {
  const { register } = useFormContext();

  return (
    <Card styles="container w-100 md:w-[630px] flex flex-col gap-4 p-4">
      <HeroTitle styles="text-lg place-self-start">
        Special Instructions
      </HeroTitle>
      <TextAreaInput
        fieldName="specialInstructions"
        register={register}
        placeholder="Leave a note"
        rows={3}
      />
    </Card>
  );
};

export default SpecialInstructionsCard;

import { createStore, persist } from "easy-peasy";
import Models from "../models";

const store = createStore(persist(Models, {
    allow: ["cart", "checkout", "order", "shop", "menu"]
}));

// Wrapping dev only code like this normally gets stripped out by bundlers
// such as webpack when creating a production build
if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("../models", () => {
      store.reconfigure(Models);
    });
  }
}

export default store;

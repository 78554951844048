export default class ShopInfoHelper {
    getAddressString(address, city, state, zipCode) {
        return address + "\n" + city + ", " + state + " " + zipCode;
    }

    getPartialAddressString(address, city) {
        return address + ", " + city;
    }

    getHoursString(daysObj) {
        var days = JSON.parse(JSON.stringify(daysObj));
        const abrev = ["M", "T", "W", "Th", "F", "Sat.", "Sun."];
        var a = [];
        let aIndex = -1;

        //put sunday to the back of the list just for the order we want
        var sunday = Object.assign({}, days[0]);
        days.splice(0, 1);
        days.push(sunday);

        for (var k = 0; k < days.length; k++) {
            var day = days[k];
            var dayOpenTime = day.isOpen
                ? this.formatTimeStr(day.openTime)
                : "";
            var dayCloseTime = day.isOpen
                ? this.formatTimeStr(day.closeTime)
                : "";
            if (
                a.length !== 0 &&
                a[aIndex].open === dayOpenTime &&
                a[aIndex].close === dayCloseTime
            ) {
                a[aIndex].end = abrev[k];
            } else {
                const elem = {
                    start: abrev[k],
                    end: "",
                    open: dayOpenTime,
                    close: dayCloseTime,
                };
                a.push(elem);
                aIndex++;
            }
        }

        var returnStr = "";
        for (var j = 0; j < a.length; j++) {
            var elem = a[j];
            const timeStr =
                elem.open === "" && elem.close === ""
                    ? "Closed"
                    : elem.open + "AM - " + elem.close + "PM";
            returnStr +=
                elem.start +
                (elem.end !== "" ? " - " + elem.end : "") +
                " " +
                timeStr +
                "\n";
        }
        return returnStr;
    }

    formatTimeStr(time) {
        if (time.substring(3, time.length) === "00") {
            time = time.substring(0, 2);
        }
        if (time.charAt(0) === "0") {
            time = time.substring(1, time.length);
        }
        return time;
    }
}

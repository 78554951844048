const getSoldOutItems = (items, menu) => {
    let soldOut = {
        items: [],
        options: [],
        inventory: []
    };
    for (let k in items) {
        let cartItem = items[k];
        if (menu && menu.categories) {
            const catHeaders = Object.keys(menu.categories);
            for (let category of catHeaders) {
                for (let item of menu.categories[category].items) {
                    if (item.menuItemId === cartItem.menuItemId) {
                        let optionKeys = Object.keys(cartItem.options)
                        for (let option of optionKeys) {
                            let menuOption = item.options.find((o) => {
                                return o.menuOptionId === cartItem.options[option].menuOptionId
                            })
                            if (menuOption !== undefined) {
                                for (let value of menuOption.values) {
                                    let cartOptionValue = cartItem.options[option].value.find(v => v.optionValueId === value.optionValueId)
                                    if (cartOptionValue !== undefined && !value.isAvailable) {
                                        const soldOutString = `The ${menuOption.title} selection "${value.value}" for "${item.name}"`;
                                        let notAdded = soldOut.options.indexOf(soldOutString) === -1
                                        if (notAdded) {
                                            soldOut.options.push(soldOutString)
                                        }
                                    }
                                }
                            }

                        }
                        if (!item.isAvailable) {
                            if (soldOut.items.indexOf(`"${cartItem.name}"`) === -1) {
                                soldOut.items.push(`"${cartItem.name}"`);
                            }
                        }
                        if (item.inventory) {
                            const cartQuantity = items.filter(i => i.menuItemId === item.menuItemId);
                            if (item.inventory.quantity < cartQuantity.length) {
                                const soldOutString = `The item "${item.name}" now has an available quantity of ${item.inventory.quantity}. Please adjust your cart to place order`;
                                let notAdded = soldOut.inventory.indexOf(soldOutString) === -1
                                if (notAdded) {
                                    soldOut.inventory.push(soldOutString)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return soldOut;
}
export default getSoldOutItems;

import PropTypes from "prop-types";
import classNames from "classnames";

const TipContainer = ({ label, value, onClick, selectedLabel }) => {
  const buttonClass = classNames(
    "flex rounded flex-col justify-center items-center w-32 p-2 border",
    {
      "border-gray-300": label !== selectedLabel,
      "border-matter-coral": label === selectedLabel
    }
  );

  const spanClass = classNames("text-sm text-center font-[500]", {
    "text-matter-gray-dark": label !== selectedLabel,
    "text-matter-coral": label === selectedLabel
  });

  return (
    <button type="button" onClick={onClick} className={buttonClass}>
      <span className={spanClass}>{label}</span>
      {value && <span className={spanClass}>${Number(value).toFixed(2)}</span>}
    </button>
  );
};

TipContainer.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedLabel: PropTypes.string,
  onClick: PropTypes.func
};

export default TipContainer;

import shopModel from "@matter/models/shop";
import cartModel from "@matter/models/cart";
import orderModel from "@matter/models/order";
import checkoutModel from "./checkout";
import menuModel from "./menu";


const Models = {
  checkout: checkoutModel,
  menu: menuModel,
  shop: shopModel,
  cart: cartModel,
  order: orderModel
};

export default Models;

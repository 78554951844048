import PropTypes from "prop-types";
import {useStoreActions} from "easy-peasy";
import {useNavigate} from "react-router-dom";
import {useFormContext} from "react-hook-form";

const OrderItem = ({item, isFromCheckout}) => {
    let {getValues} = useFormContext();
    const { setFormValues } = useStoreActions((actions) => actions.checkout);
    let optionValues = [];
    let navigate = useNavigate();
    if (item !== null) {
        const optionKeys = Object.keys(item.options);
        for (let i = 0; i < optionKeys.length; i++) {
            item.options[optionKeys[i]].value.forEach(v => {
                optionValues.push(v.value);
            });
        }
    }

    const removeItem = useStoreActions(actions => actions.cart.removeItem);

    return (
        <div className="flex flex-row first:mt-4">
            <div className="flex flex-col basis-[80%] items-start" onClick={()=> {setFormValues(getValues()); navigate(`../${item.category}/${item.menuItemId}?edit=true&cartId=${item.id}`)}}>
                <span className="text-sm text-matter-green font-default underline cursor-pointer">
          {item.name}
        </span>
                <span className="text-[13px] font-[500] text-matter-gray-light">{optionValues.join(", ")}</span>
            </div>
            <div className={"flex flex-col"}>
                <span className="basis-[15%] text-right text-sm">${Number(item.priceTotal).toFixed(2)}</span>
                {isFromCheckout && <span className="text-[13px] font-[500] underline text-matter-gray-light cursor-pointer" onClick={() => removeItem(item.id)}>Remove</span>}
            </div>
        </div>
    );
};

OrderItem.propTypes = {
    item: PropTypes.object.isRequired,
    isFromCheckout: PropTypes.bool
};

OrderItem.defaultProps = {
    isFromCheckout: false
};

export default OrderItem;

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { widgetTypes } from "@matter/util/WidgetTypes";
import HeroTitle from "@matter/common/HeroTitle";

const CheckOption = ({ widgetType, option, cartItem}) => {
  const { register, setValue, getValues } = useFormContext();
  return (
    <>
        <div className={"my-6"}>
            <HeroTitle styles="place-self-start text-lg font-[500]">
                {option.title}
            </HeroTitle>
        </div>
      <div className={"grid sm:grid-cols-2 gap-[15px] grid-cols-1"}>
        {option.values.filter(value => value.isAvailable).map((v) => (
          <div
            className={
              "flex justify-start border-solid rounded border-[1px] cursor-pointer w-[100%] pl-3 h-[90px] border-matter-gray-border items-center"
            }
            key={v.optionValueId}
            onClick={() => setValue(`${option.menuOptionId}:${v.optionValueId}`, !getValues(`${option.menuOptionId}:${v.optionValueId}`))}>
            <input
              className={"text-matter-coral active:text-matter-gray-light"}
              defaultChecked={cartItem !== undefined && cartItem.options[option.title] !== undefined && cartItem.options[option.title].value.find(value => v.optionValueId === value.optionValueId) !== undefined}
              {...register(`${option.menuOptionId}:${v.optionValueId}`, {})}
              type="checkbox"
              id={v.value}
            />
            <div className={"flex flex-col align-middle ml-4"}>
              <label
                className="place-self-start  text-matter-gray-light font-medium mb-0"
                htmlFor={v.value}>
                {v.value}
              </label>
              <span className={"text-sm font-[500]"}>${v.price.toFixed(2)}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

CheckOption.propTypes = {
  widgetType: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
    cartItem: PropTypes.object
};

export default CheckOption;

import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate, useLocation } from "react-router-dom";
import CoffeeIconForItemHelper from "@matter/util/CoffeeIconForItemHelper";

const MenuItem = ({ item, category }) => {
  const imageHelper = new CoffeeIconForItemHelper();
  const { menu } = useStoreState((state) => state.menu);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const addToCartMode = params.get("addToCart") === "true";
  const navigate = useNavigate();
  return (
    <div
      className={"text-center cursor-pointer"}
      onClick={() => {
        if (item.isAvailable) {
          navigate(
            `${category}/${item.menuItemId}${
              addToCartMode ? "/?addToCart=true" : ""
            }`
          );
        }
      }}>
      <div className={`${!item.isAvailable ? "opacity-50" : "opacity-100"} max-w-[215px]`}>
        <img
          className={"rounded-md w-[100%]"}
          src={imageHelper.getImageForItemNameFromMenu(item.name, menu)}
        />
      <div className={"py-[16px]"}>
        <span className={"text-[16px] font-[500]"}>{item.name}</span>
      </div>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object,
  category: PropTypes.string
};

export default MenuItem;

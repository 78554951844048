import PropTypes from "prop-types";
import classNames from "classnames";

const TextButton = ({ children, styles, ...rest }) => {
  const classes = classNames(
    {
      [`${styles}`]: !!styles
    },
    "bg-none border-none cursor-pointer justify-center p-1 place-content-center hover:opacity:50 disabled:cursor-not-allowed text-matter-gold font-semibold flex"
  );

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

TextButton.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.string,
  rest: PropTypes.any
};

TextButton.defaultProps = {
  styles: ""
};

export default TextButton;

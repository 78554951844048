import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorMessage from "../ErrorMessage";

const TextAreaInput = ({ styles, register, fieldName, error, ...rest }) => {
  const classes = classNames(
    {
      "!border-red-500": !!error
    },
    {
      [`${styles}`]: !!styles
    },
    "shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-matter-gold focus:shadow-outline without-ring"
  );

  return (
    <>
      <textarea className={classes} {...rest} {...register(fieldName)} />
      <ErrorMessage error={error} />
    </>
  );
};

TextAreaInput.propTypes = {
  styles: PropTypes.string,
  register: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  error: PropTypes.object,
  rest: PropTypes.any
};

TextAreaInput.defaultProps = {
  styles: ""
};

export default TextAreaInput;

import { Route, Routes } from "react-router-dom";
import Menu from "@matter/views/Menu";
import Layout from "@matter/components/Layout";
import "./App.css";

import MenuOptions from "@matter/views/MenuOptions";
import GuestCheckoutPage from "@matter/views/GuestCheckoutPage";
import OrderComplete from "@matter/views/OrderComplete";

function App() {
  return (
    <Routes>
      <Route path={":shopId/*"} element={<Layout />}>
        <Route index element={<Menu />} />
        <Route path={":category/:itemId"} element={<MenuOptions />} />
        <Route path={"checkout"} element={<GuestCheckoutPage />} />
        <Route path={":orderId"} element={<OrderComplete />} />
      </Route>
    </Routes>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";

const ErrorMessage = React.forwardRef(({ error }, ref) => {
  return (
    error && (
      <span className="text-sm text-red-500 ml-1 mt-1" ref={ref}>
        {error?.message}
      </span>
    )
  );
});

ErrorMessage.propTypes = {
  error: PropTypes.object
};
ErrorMessage.displayName = "Error Message";

export default ErrorMessage;

import PropTypes from "prop-types";

const QuantityChooser = ({ onDecrease, onIncrease, total, twoCols }) => {
  return (
    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-[15px] mt-[20px]"}>
      <div
        className={"flex justify-between border-solid rounded border-[1px] pl-3 col-span-2 h-[90px] border-matter-gray-border items-center"}>
        <div>
          <span className={"text-matter-gray-dark font-[500]"}>Qty</span>
        </div>
        <div className={"flex justify-center items-center"}>
          <button
            type={"button"}
            className={
              "w-[40px] h-[40px] font-[500] flex items-center justify-center bg-matter-gray-lightest rounded-[50%] mx-5 cursor-pointer"
            }
            onClick={() => onDecrease()}>
            -
          </button>
          <span className={"font-[500] select-none"}>{total}</span>
          <button
            type={"button"}
            className={
              "w-[40px] h-[40px] font-[500] flex items-center justify-center bg-matter-gray-lightest rounded-[50%] mx-5 cursor-pointer"
            }
            onClick={() => onIncrease()}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

QuantityChooser.propTypes = {
  onDecrease: PropTypes.func.isRequired,
  onIncrease: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  twoCols: PropTypes.bool
};

export default QuantityChooser;

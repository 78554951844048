import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import MaskedInput from "@matter/common/Form/MaskedInput";
import Label from "@matter/common/Form/Label";

const TipOtherAmount = ({ selectedLabel }) => {
  const { control } = useFormContext();

  return selectedLabel === "Other" ? (
    <fieldset className="flex flex-col md:w-1/2 mb-4 justify-between">
      <Label styles="place-self-start" htmlFor="tip">
        Enter an amount
      </Label>
      <Controller
        name="tip"
        render={({ field }) => (
          // TODO: Amount needs to be able to change as a person puts more digits in
          <MaskedInput // eslint-disable-next-line react/prop-types
            placeholder="$0.00"
            mask="$num"
            blockOptions={{
              num: {
                mask: Number,
                thousandsSeparator: ",",
                radix: "."
              }
            }}
            {...field}
          />
        )}
        control={control}
      />
    </fieldset>
  ) : null;
};

TipOtherAmount.propTypes = {
  selectedLabel: PropTypes.string
};

TipOtherAmount.defaultProps = {
  selectedLabel: ""
};

export default TipOtherAmount;

import {action, thunk, thunkOn} from "easy-peasy";
import axios from "axios";
import { assign } from "lodash";
import {baseUrl} from "@matter/baseUrl";


const checkoutModel = {
    hasFood: false,
    clientSecret: null,
    paymentIntentId: null,
    defaultFormValues: {
        // Contact Info:
        firstName: "",
        lastName: "",
        email: "",
        emailConfirmation: "",
        phone: "",

        // Tip
        tip: 0,

        // Pickup or Delivery?
        deliveryOrPickup: 0,

        // Payment method
        nameOnCard: "",
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        tableNumber: null,
        // Special instructions
        specialInstructions: ""
    },
    setClientSecret: action((state, clientSecret) => {
        state.clientSecret = clientSecret;
    }),
    setPaymentIntentId: action((state, paymentIntentId) => {
        state.paymentIntentId = paymentIntentId;
    }),
    fetchClientSecret: thunk(async (actions) => {
        const response = await axios.post(`${baseUrl}/create_payment_intent`, {amount: 100});
        if(response.status === 200) {
            actions.setClientSecret(response.data.clientSecret);
            actions.setPaymentIntentId(response.data.id);
        }
    }),
    updatePaymentIntent: thunk(async (actions, data) => {
        return await axios.post(`${baseUrl}/update_payment_intent`, {amount: (data.amount), paymentIntent: data.paymentIntent })
            .catch(error => error);
    }),
    setData: action((state, data) => {
        assign(data, state);
    }),
    setFormValues: action((state, data) => {
        state.defaultFormValues = data;
    })
}

export default checkoutModel;

// I have a feeling this file can be refactored to be much more clear
import React, {useEffect, useState} from "react";
import { IMaskMixin } from "react-imask";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage";

const MASK = (mask, blockOptions) => [
  {
    mask: ""
  },
  {
    mask: mask,
    lazy: false,
    blocks: { ...blockOptions }
  }
];

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <input ref={inputRef} {...props} />
));

const MaskedInput = ({
  defaultValue,
  onChange,
  mask,
  blockOptions,
  placeholder,
  error,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue?.toString() || "");
  useEffect(() => {
    if(props.value !== "") {
      setValue(props.value);
      onChange(props.value);
    }
  }, []);
  const handleAccept = (v) => {
    setValue(v);
    onChange(v);
  };

  return (
    <>
      <IMaskInput
        {...props}
        mask={MASK(mask, blockOptions)}
        unmask
        onAccept={handleAccept}
        placeholder={placeholder}
        value={value}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      <ErrorMessage error={error} />
    </>
  );
};

MaskedInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  mask: PropTypes.string,
  blockOptions: PropTypes.object,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default MaskedInput;

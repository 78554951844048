import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import {
  useStripe,
  useElements,
  CardNumberElement
} from "@stripe/react-stripe-js";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import HeroTitle from "@matter/common/HeroTitle";
import ContactInfoCard from "@matter/components/checkout/ContactInfoCard";
import TipCard from "@matter/components/checkout/TipCard";
import DeliveryOrPickupCard from "@matter/components/checkout/DeliveryOrPickupCard";
import PaymentMethodCard from "@matter/components/checkout/PaymentMethodCard";
import SpecialInstructionsCard from "@matter/components/checkout/SpecialInstructionsCard";
import OrderSummaryCard from "@matter/components/checkout/OrderSummaryCard";
import { baseUrl } from "@matter/baseUrl";
import checkoutSchema from "@matter/form_utils/CheckoutSchemas";
import Button from "@matter/common/Button";

import "react-toastify/dist/ReactToastify.css";
import getSoldOutItems from "@matter/util/MenuHelper";

const GuestCheckoutPage = ({ clientSecret }) => {
  const { defaultFormValues } = useStoreState((state) => state.checkout);
  const { setFormValues } = useStoreActions((actions) => actions.checkout);
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(checkoutSchema),
    criteriaMode: "firstError"
  });
  const { watch, setValue } = methods;
  const tip = watch("tip");
  const stripe = useStripe();
  const elements = useElements();

  const { cart } = useStoreState((state) => state.cart);
  const { setClientSecret, setPaymentIntentId, updatePaymentIntent } =
    useStoreActions((actions) => actions.checkout);
  const { setMenu, getMenu } = useStoreActions((actions) => actions.menu);
  const { menu } = useStoreState((state) => state.menu);
  const { clearCart } = useStoreActions((actions) => actions.cart);
  const [loading, setLoading] = useState(false);
  const { shopId, fees, shopInfo } = useStoreState((state) => state.shop);
  const { getServiceFees } = useStoreActions((actions) => actions.shop);
  const paymentIntentId = useStoreState(
    (state) => state.checkout.paymentIntentId
  );
  const navigate = useNavigate();
  const buttons = JSON.parse(shopInfo.checkoutButtons).filter(
    (b) => !b.memberOnly
  );

  const ORDER_SUMMARY_DEFAULT_VALUES = {
    costObject: {
      Subtotal: 0,
      Fees: 0,
      "Sales tax": 0,
      Tip: 0
    },
    costTotal: 0,
    termsOfService: (
      <span>
        By placing an order, I accept the CoffeePass{" "}
        <a
          className={"underline"}
          href={"https://coffeepass.io/#/terms"}
          rel="noreferrer noopener"
          target={"_blank"}>
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          className={"underline"}
          rel="noreferrer noopener"
          href={"https://coffeepass.io/#/privacy"}
          target={"_blank"}>
          Privacy Policy
        </a>{" "}
      </span>
    )
  };

  const hasNonEventItemsInCart = cart.some((item) => !item.isEvent);

  const [orderSummaryObj, setOrderSummaryObj] = useState({
    ...ORDER_SUMMARY_DEFAULT_VALUES
  });

  useEffect(() => {
    getMenu(shopId);
  }, []);

  useEffect(() => {
    const getOrderSummaryData = async () => {
      let subTotal = 0;
      cart.forEach((item) => (subTotal += item.priceTotal));

      orderSummaryObj.costObject.Subtotal = subTotal;
      orderSummaryObj.costObject["Sales tax"] =
        orderSummaryObj.costObject.Subtotal * (shopInfo.tax / 100);
      orderSummaryObj.costObject.Tip = Number(tip);

      const feeTotal =
        orderSummaryObj.costObject.Subtotal +
        orderSummaryObj.costObject.Tip +
        orderSummaryObj.costObject["Sales tax"];

      if (isEmpty(fees)) await getServiceFees();

      let fee = fees.find((item) => {
        return feeTotal <= item.rangeMax && feeTotal >= item.rangeMin;
      });

      orderSummaryObj.costObject.Fees = (fee?.fee / 100) * feeTotal;
      orderSummaryObj.costTotal = orderSummaryObj.costObject.Fees + feeTotal;

      return setOrderSummaryObj({ ...orderSummaryObj });
    };

    getOrderSummaryData();
  }, [shopInfo, tip, fees, cart]);

  useEffect(() => {
    if (!hasNonEventItemsInCart) {
      setValue("tip", 0);
    }
  }, [hasNonEventItemsInCart]);

  // Calculating the tip object:
  const getTipAmount = (percentage) => {
    return ((percentage / 100) * orderSummaryObj.costObject.Subtotal).toFixed(
      2
    );
  };

  const tips = {
    "10%": getTipAmount(10),
    "20%": getTipAmount(20),
    "25%": getTipAmount(25),
    "30%": getTipAmount(30),
    Other: ""
  };

  useEffect(() => {
    const subscription = methods.watch(() => {});
    getServiceFees();
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const [errorMessage, setErrorMessage] = useState(null);
  const notify = (error) => {
    toast.error(error, {
      position: "top-center"
    });
  };

  const handleCardPayment = async (event, data) => {
    setLoading(true);
    event.preventDefault();
    let updateResponse = await updatePaymentIntent({
      amount: (orderSummaryObj.costTotal * 100).toFixed(0),
      paymentIntent: paymentIntentId
    });

    if (updateResponse.status === 200) {
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        }
      );

      if (error) {
        notify(error.message);
        setPaymentIntentId(null);
        setClientSecret(null);
        setLoading(false)
      } else {
        let comment = "";

        if (data.deliveryOrPickup !== 0) {
          comment += buttons[data.deliveryOrPickup].label;
          if (data.tableNumber !== null) {
            comment += " | Table Number: " + data.tableNumber;
          }
        }
        if (data.specialInstructions.length > 0) {
          comment += `, ${data.specialInstructions}`;
        }

        let body = {
          email: data.email,
          name: `${data.firstName} ${data.lastName}`,
          phone: data.phone,
          total: orderSummaryObj.costTotal.toFixed(2),
          tax: orderSummaryObj.costObject["Sales tax"].toFixed(2),
          tip: hasNonEventItemsInCart ? orderSummaryObj.costObject.Tip.toFixed(2) : 0.00,
          serviceFee: orderSummaryObj.costObject.Fees.toFixed(2),
          comment: comment,
          items: JSON.stringify(cart),
          shopId: parseInt(shopId),
          stripeTransactionId: paymentIntent.id
        };

        let response = await axios
          .post(`${baseUrl}/charge_customer_guest`, body)
          .catch((error) => {
            setLoading(false);
            notify(error.response.data.error);
            setPaymentIntentId(null);
            setClientSecret(null);
          });
        if (response.status === 200) {
          setPaymentIntentId(null);
          setClientSecret(null);
          clearCart();
          setFormValues({
            // Contact Info:
            firstName: "",
            lastName: "",
            email: "",
            emailConfirmation: "",
            phone: "",

            // Tip
            tip: 0,

            // Pickup or Delivery?
            deliveryOrPickup: 0,

            // Payment method
            nameOnCard: "",
            cardNumber: "",
            cardExpiry: "",
            cardCvc: "",
            tableNumber: null,
            // Special instructions
            specialInstructions: ""
          });
          setMenu(null);
          navigate(`../${response.data.orderId}`);
          setLoading(false);
        }
      }
    } else {
      notify("There was an issue with the payment. Please try again.");
      setLoading(false);
      setPaymentIntentId(null);
      setClientSecret(null);
    }
  };

  const onSubmit = async (data, event) => {
    setFormValues(methods.getValues());
    if (checkForSoldOutItems()) {
      await handleCardPayment(event, data);
    }
  };

  const checkForSoldOutItems = () => {
    const soldOutItems = getSoldOutItems(cart, menu);
    if (
      soldOutItems.items.length !== 0 ||
      soldOutItems.options.length !== 0 ||
      soldOutItems.inventory.length !== 0
    ) {
      let soldOutString = "";
      let soldOutError = "";
      let soldOutOptionsString = "";
      let soldOutOptionsError = "";

      if (soldOutItems.items.length === 2) {
        soldOutError =
          soldOutItems.items[0] +
          " and " +
          soldOutItems.items[1] +
          " are now sold out. Please remove these items from your cart to place order.";
      } else if (soldOutItems.items.length > 2) {
        soldOutItems[soldOutItems.items.length - 1] =
          "and " + soldOutItems.items[soldOutItems.itemslength - 1];
        soldOutString = soldOutItems.items.join(", ");
        soldOutError =
          soldOutString +
          " are now sold out. Please remove these items from your cart to place order.";
      } else if (soldOutItems.items.length > 0) {
        soldOutError =
          soldOutItems.items[0] +
          " is now sold out. Please remove the item from your cart to place order.";
      }

      if (soldOutItems.options.length === 2) {
        soldOutOptionsError =
          soldOutItems.options[0] +
          " and " +
          soldOutItems.options[1] +
          " are now sold out. Please remove or modify these items to place order.";
      } else if (soldOutItems.options.length > 2) {
        soldOutItems[soldOutItems.options.length - 1] =
          "and " + soldOutItems.options[soldOutItems.options.length - 1];
        soldOutOptionsString = soldOutItems.options.join(", ");
        soldOutOptionsError =
          soldOutOptionsString +
          " are now sold out. Please remove ot modify these items to place order.";
      } else if (soldOutItems.options.length > 0) {
        soldOutOptionsError =
          soldOutItems.options[0] +
          " is now sold out. Please remove or modify the item to place order.";
      }

      soldOutError += " " + soldOutOptionsError;
      soldOutError += soldOutItems.inventory.join(". ");

      notify(soldOutError);
      return false;
    } else {
      return true;
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col w-[100vw] h-[100vh] overflow-auto bg-white">
        <header className="w-full lg:px-40 px-5 py-5 flex flex-col gap-12">
          <div
              className="flex flex-row gap-2 cursor-pointer jus"
              onClick={() => navigate(`/${shopId}`, { replace: true })}>
          <span>
            <ArrowBack
                sx={{
                  color: grey[500]
                }}
            />
          </span>
            <span
                className="font-[500] tracking-[.18em] text-[14px] flex-col self-center text-matter-gray-light">
              BACK TO {shopInfo?.shopName?.toUpperCase()}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <HeroTitle styles="place-self-start text-[30px] font-[700] ">CHECKOUT</HeroTitle>
            <hr className="w-11 h-1 place-self-start bg-matter-gold" />
          </div>
          {isEmpty(cart) && (
            <div className={"flex flex-col p-3 max-w-fit"}>
              <span className={"text-matter-green font-semibold"}>
                Your cart is empty
              </span>
              <Button
                styles={"mt-4 px-[100px]"}
                onClick={() => navigate(`/${shopId}`)}>
                Add item to cart
              </Button>
            </div>
          )}
        </header>
        {!isEmpty(cart) && (
          <form
            onSubmit={methods.handleSubmit(async (data, event) => {
              await onSubmit(data, event);
            })}
            className="flex flex-col md:flex-row w-full h-fit bg-matter-gray-lightest md:py-5">
            <div className="flex flex-col gap-0 md:gap-8 basis-full md:basis-7/12 content-end items-end md:pr-12">
              <ContactInfoCard />
              {hasNonEventItemsInCart && (
                <>
                  <TipCard tips={tips} />
                  <DeliveryOrPickupCard buttons={buttons} />
                </>
              )}
              <PaymentMethodCard />
              <SpecialInstructionsCard />
            </div>
            <div className="flex flex-col basis-full md:basis-5/12 justify-start">
              <OrderSummaryCard
                cart={cart}
                loading={loading}
                {...orderSummaryObj}
              />
            </div>
          </form>
        )}
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

GuestCheckoutPage.propTypes = {
  clientSecret: PropTypes.string
};

export default GuestCheckoutPage;

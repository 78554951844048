import { useFormContext, Controller } from "react-hook-form";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from "@stripe/react-stripe-js";
import Card from "@matter/common/Card";
import Label from "@matter/common/Form/Label";
import TextInput from "@matter/common/Form/TextInput";
import HeroTitle from "@matter/common/HeroTitle";

const CARD_OPTIONS = {
  showIcon: true,

  classes: {
    base: "shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-[#999999] leading-tight focus:outline-none focus:shadow-outline focus:border-matter-gold without-ring",
    focus: "outline-none shadow-outline border-matter-gold without-ring",
    invalid: "border-red-300"
  },
  style: {
    base: {
      "::placeholder": {
        color: "#999999"
      }
    }
  }
};

const PaymentMethodCard = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <Card styles="container w-100 md:w-[630px] flex flex-col gap-2 p-5">
      <HeroTitle styles="text-lg place-self-start">Payment method</HeroTitle>
      <fieldset className="flex flex-col md:flex-row gap-4 mt-[24px] mb-4 justify-between">
        <div className="form-row basis-full">
          <Label styles="place-self-start" htmlFor="nameOnCard">
            Name on card
          </Label>
          <TextInput
            register={register}
            error={errors?.nameOnCard}
            fieldName="nameOnCard"
          />
        </div>
      </fieldset>
      <fieldset className="flex flex-row mb-4">
        <div className="form-row basis-full">
          <Label styles="place-self-start" htmlFor="cardNumber">
            Card number
          </Label>
          <Controller
            name="cardNumber"
            render={({ field }) => (
              <CardNumberElement options={CARD_OPTIONS} {...field} />
            )}
            control={control}
          />
        </div>
      </fieldset>
      <fieldset className="flex flex-col md:flex-row gap-4 mb-4 justify-between">
        <div className="form-row md:basis-1/2">
          <Label styles="place-self-start" htmlFor="cardExpiry">
            Expiration
          </Label>
          <Controller
            name="cardExpiry"
            render={({ field }) => (
              <CardExpiryElement options={CARD_OPTIONS} {...field} />
            )}
            control={control}
          />
        </div>
        <div className="form-row md:basis-1/2">
          <Label styles="place-self-start" htmlFor="cardCvc">
            CVC
          </Label>
          <Controller
            name="cardCvc"
            render={({ field }) => (
              <CardCvcElement options={CARD_OPTIONS} {...field} />
            )}
            control={control}
          />
        </div>
      </fieldset>
    </Card>
  );
};

export default PaymentMethodCard;

import {action, thunk} from "easy-peasy";
import axios from "axios";
import {baseUrl} from "@matter/baseUrl";

const shopModel = {
    shopId: null,
    shopInfo: null,
    hours: [],
    membershipInfo: null,
    setShopId: action((state, data) => {
        state.shopId = data;
    }),
    fees: [],
    setShopInfo: action((state, data) => {
        state.shopInfo = data;
    }),
    setServiceFees: action((state, fees) => {
        state.fees = fees
    }),
    getServiceFees: thunk(async (actions) => {
        const response = await axios.get(`${baseUrl}/get_fee_ranges`);
        if(response.status === 200) {
            actions.setServiceFees(response.data);
        }
    }),
    getShopInfo: thunk(async (actions, shopId) => {
        axios.get(`${baseUrl}/get_shop_info?shop_id=${shopId}`)
            .then(result => {
                if(result.status === 200) {
                    actions.setShopInfo(result.data);
                }
            })
    }),
    setShopHours: action((state, data) => {
        state.hours = data;
    }),
    setMembershipInfo: action((state, data) => {
        state.membershipInfo = data;
    }),
    getShopHours: thunk(async (actions, shopId) => {
        axios.get(`${baseUrl}/get_shop_hours?shop_id=${shopId}`)
            .then(result => {
                if(result.status === 200) {
                    actions.setShopHours(result.data);
                }
            })
    }),
    getMembershipInfo: thunk(async (actions, membershipId) => {
        const response = await axios.get(`${baseUrl}/shop_membership/?membershipId=${membershipId}`);
        if(response.status === 200) {
            actions.setMembershipInfo(response.data);
        }
    }),
}

export default shopModel;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import Card from "@matter/common/Card";
import HeroTitle from "@matter/common/HeroTitle";
import TipContainer from "./components/TipContainer";
import TipOtherAmount from "./components/TipOtherAmount";

const TipCard = ({ tips }) => {
  const { setValue } = useFormContext();
  const tipEntries = Object.entries(tips);
  const tipKeys = Object.keys(tips);
  const [selectedTipLabel, setSelectedTipLabel] = useState(null);

  useEffect(() => {
      if(selectedTipLabel === null) {
          setSelectedTipLabel(tipKeys[0]);
      }
      if (selectedTipLabel && selectedTipLabel !== "Other") {
      setValue("tip", tips[selectedTipLabel], {
        shouldValidate: true
      });
    }
  }, [selectedTipLabel]);


  const TipBoxes = isEmpty(tipEntries)
    ? null
    : tipEntries.map(([label, value]) => {
        return (
          <TipContainer
            key={`${value}-${label}`}
            onClick={() => setSelectedTipLabel(label)}
            value={value}
            selectedLabel={selectedTipLabel}
            label={label}
          />
        );
      });

  return (
    <Card styles="container w-100 md:w-[630px] flex flex-col gap-4 p-5">
      <HeroTitle styles="text-lg place-self-start">Tip</HeroTitle>
      <div className="flex flex-row justify-between gap-1">{TipBoxes}</div>
      <TipOtherAmount selectedLabel={selectedTipLabel} />
    </Card>
  );
};

TipCard.propTypes = {
  tips: PropTypes.object.isRequired
};

export default TipCard;

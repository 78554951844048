import PropTypes from "prop-types";
import classNames from "classnames";

const HeroTitle = ({ children, styles, fontSize }) => {
  const classes = classNames(
    {
      [`${styles}`]: !!styles
    },
    "text-matter-green tracking-[.1em] font-semibold"
  );

  return (
    <span className={classes} style={{fontSize: fontSize}}>
      {children}
    </span>
  );
};

HeroTitle.defaultProps = {
  styles: ""
};

HeroTitle.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.string,
  fontSize: PropTypes.number
};

export default HeroTitle;

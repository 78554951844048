const getPickupTime = (cart) => {
        let pickupTime = cart[0].pickupTime;
        let fields = pickupTime.split(" ");
        let time = fields[0];
        let times = time.split("-");
        let minTime = times[0];
        let maxTime = times[1];

        cart.map((obj) => {
            let opickupTime = obj.pickupTime;
            let ofields = opickupTime.split(" ");
            let otime = ofields[0];
            let otimes = otime.split("-");
            let ominTime = otimes[0];
            let omaxTime = otimes[1];

            if (maxTime < omaxTime) {
                minTime = ominTime;
                maxTime = omaxTime;
            }
        });

        return {minTime: minTime, maxTime: maxTime};
    }

    export default getPickupTime;

import PropTypes from "prop-types";
import {useStoreActions, useStoreState} from "easy-peasy";
import {useFormContext} from "react-hook-form";
import {useEffect} from "react";
import {Oval} from "react-loader-spinner";
import {useNavigate} from "react-router-dom";
import ShopInfoHelper from "@matter/util/ShopInfoHelper";
import TimeHelper from "@matter/util/TimeHelper";
import Button from "@matter/common/Button";

const ShopInfoCard = ({children, showCartButton}) => {
    const shopInfo = useStoreState((actions) => actions.shop.shopInfo);
    const shopId = useStoreState((actions) => actions.shop.shopId);
    const getShopInfo = useStoreActions((actions) => actions.shop.getShopInfo);
    const hours = useStoreState((state) => state.shop.hours);
    const getShopHours = useStoreActions((actions) => actions.shop.getShopHours);
    const {cart} = useStoreState((state) => state.cart);
    const navigate = useNavigate();

    useEffect(() => {
        if (shopId) {
            getShopInfo(shopId);
            getShopHours(shopId);
        }
    }, [shopId]);

    let shopHours = "";
    let operationHour = "";
    let operationStatus = "";
    let operationStatusColor = "#999999";
    if (hours && hours.length !== 0 && shopInfo !== null) {
        const shopInfoHelper = new ShopInfoHelper();
        shopHours = shopInfoHelper.getHoursString(hours);
        const timeHelper = new TimeHelper();

        operationHour = timeHelper.shopOperationHour(hours);

        if (operationHour.includes("Open")) {
            operationHour = operationHour.slice(4);

            if (shopInfo.isPaused) {
                operationStatus = "Paused";
            } else {
                operationStatus = "Open";
            }
        } else if (operationStatus.includes("Closed")) {
            operationStatus = "Closed";
            operationHour = operationHour.slice(6);
        }
    }
    return (
        <div
            className={
                "h-fit shadow-md bg-white rounded top-[65px] flex flex-shrink-0 justify-center sm:sticky top-3 mt-[20px] lg:ml-[40px] scroll-mt-[60px] sm:scroll-pt-[100px]"
            }>
            {shopInfo === null ? (
                <Oval
                    height={80}
                    width={80}
                    color="#F7F7F7"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#074330"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            ) : (
                <div className={"flex flex-col justify-center text-center"}>
                    <div className={"w-[100%] h-[160px] lg:h-[320px] lg:w-[385px] rounded-t-md overflow-hidden"}>
                        <img
                            className={"relative top-[50%] translate-y-[-50%] lg:w-[385px] lg:h-[320px] lg:translate-y-[0] lg:top-[0] "}
                            src={shopInfo.bannerUrl}
                        />
                    </div>
                    <div
                        className={
                            "flex flex-col md:flex-row lg:flex-col justify-between p-[10px] w-[100%]"
                        }>
                        <div className={"mt-[10px]"}>
              <span
                  className={
                      "text-matter-green font-bold text-[20px] leading-[27px] tracking-[4px]"
                  }>
                {shopInfo?.shopName?.toUpperCase()}
              </span>

                            <div className={"mb-1"}>
                <span
                    className={`${
                        operationStatus === "Open"
                            ? "text-matter-gold"
                            : "text-matter-coral"
                    } text-[12px] font-semibold font-default`}>
                  {operationStatus}
                </span>
                                <span className={"text-matter-gray font-semibold leading-[15px] ml-2 text-[12px]"}>
                  {operationHour}
                </span>
                            </div>
                        </div>
                        {children}
                        {cart.length > 0 && showCartButton && (
                            <div className={"md:w-[350px] m-[20px]"}>
                                <Button
                                    styles={"w-[100%] h-[40px] rounded-[4px]"}
                                    onClick={() => navigate("./checkout")}>
                                    View cart
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

ShopInfoCard.propTypes = {
    children: PropTypes.node,
    showCartButton: PropTypes.bool
};

ShopInfoCard.defaultProps = {
    showCartButton: true
};

export default ShopInfoCard;

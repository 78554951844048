export default class TimeHelper {
    isShopOpen(daysObj) {
        var days = JSON.parse(JSON.stringify(daysObj));

        var today = new Date();
        var dayNum = today.getDay();

        const day = days[dayNum];
        if (!day.isOpen) {
            return false;
        }

        var shopOpenHourTemp = parseInt(day.openTime.substring(0, 2));
        if (shopOpenHourTemp === 12) {
            //check if open hour is 12am
            shopOpenHourTemp = 0;
        }
        const shopOpenHour = shopOpenHourTemp;

        const shopOpenMinute = parseInt(day.openTime.substring(3, 5));

        const shopCloseHour = parseInt(day.closeTime.substring(0, 2)) + 12; //+12 because Date.gethours uses military time
        const shopCloseMinute = parseInt(day.closeTime.substring(3, 5));

        const hour = today.getHours();
        const minute = today.getMinutes();

        if (hour === shopOpenHour) {
            return minute > shopOpenMinute;
        }
        if (hour === shopCloseHour) {
            return minute < shopCloseMinute;
        }
        return hour > shopOpenHour && hour < shopCloseHour;

    }

    shopOperationHour(daysObj) {
        var days = JSON.parse(JSON.stringify(daysObj));

        var today = new Date();
        var dayNum = today.getDay();

        const day = days[dayNum];
        if (!day.isOpen) {
            return `Closed ${day.openTime}am - ${day.closeTime}pm`;
        }

        var shopOpenHourTemp = parseInt(day.openTime.substring(0, 2));
        if (shopOpenHourTemp === 12) {
            //check if open hour is 12am
            shopOpenHourTemp = 0;
        }
        const shopOpenHour = shopOpenHourTemp;

        const shopOpenMinute = parseInt(day.openTime.substring(3, 5));

        const shopCloseHour = parseInt(day.closeTime.substring(0, 2)) + 12;
        const shopCloseMinute = parseInt(day.closeTime.substring(3, 5));

        const hour = today.getHours();
        const minute = today.getMinutes();

        if (hour === shopOpenHour) {
            if (minute > shopOpenMinute) {
                return `Open  ${day.openTime}am - ${day.closeTime}pm`;
            } else {
                return `Closed  ${day.openTime}am - ${day.closeTime}pm`;
            }
        }
        if (hour === shopCloseHour) {
            if (minute < shopCloseMinute) {
                return `Open  ${day.openTime}am - ${day.closeTime}pm`;
            } else {
                return `Closed  ${day.openTime}am - ${day.closeTime}pm`;
            }
        }
        if (hour > shopOpenHour && hour < shopCloseHour) {
            return `Open  ${day.openTime}am - ${day.closeTime}pm`;
        }
        return `Closed  ${day.openTime}am - ${day.closeTime}pm`;
    }
}

import PropTypes from "prop-types";
import classNames from "classnames";

const Label = ({ styles, ...rest }) => {
  const classes = classNames(
    {
      [`${styles}`]: !!styles
    },
    "block text-gray-700 text-[13px] font-[500] mb-2"
  );

  return <label className={classes} {...rest} />;
};

Label.propTypes = {
  styles: PropTypes.string
};

Label.defaultProps = {
  styles: ""
};

export default Label;

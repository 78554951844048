import { useFormContext, Controller } from "react-hook-form";
import TextInput from "@matter/common/Form/TextInput";
import Label from "@matter/common/Form/Label";
import Card from "@matter/common/Card";
import MaskedInput from "@matter/common/Form/MaskedInput";
import HeroTitle from "@matter/common/HeroTitle";

const ContactInfoCard = () => {
  const {
    register,
    control,
    formState: { errors },
      setValue
  } = useFormContext();

  return (
    <Card styles="container w-100 md:w-[630px] flex flex-col gap-2 p-5">
      <HeroTitle styles="text-[20px] font-[500] place-self-start mb-4">
        Contact Information
      </HeroTitle>
      <fieldset className="flex flex-col md:flex-row gap-4 mb-4 justify-between">
        <div className="form-row basis-full md:basis-1/2">
          <Label styles="place-self-start" htmlFor="firstName">
            First name
          </Label>
          <TextInput
            error={errors?.firstName}
            fieldName="firstName"
            register={register}
          />
        </div>

        <div className="form-row basis-full md:basis-1/2">
          <Label styles="place-self-start" htmlFor="lastName">
            Last name
          </Label>
          <TextInput
            error={errors?.lastName}
            fieldName="lastName"
            register={register}
          />
        </div>
      </fieldset>

      <fieldset className="mb-4">
        <div className="form-row">
          <Label styles="place-self-start" htmlFor="email">
            Email
          </Label>
          <TextInput
            error={errors?.email}
            fieldName="email"
            register={register}
            type="email"
          />
        </div>
      </fieldset>

      <fieldset className="mb-4">
        <div className="form-row">
          <Label styles="place-self-start" htmlFor="confirmEmail">
            Confirm email
          </Label>
          <TextInput
            error={errors?.emailConfirmation}
            fieldName="emailConfirmation"
            register={register}
            type="email"
          />
        </div>
      </fieldset>

      <fieldset className="flex flex-row mb-4">
        <div className="form-row basis-full md:basis-1/2">
          <Label styles="place-self-start" htmlFor="phone">
            Mobile #
          </Label>
          <Controller
            name="phone"
            render={({ field }) => (
              <MaskedInput
                // eslint-disable-next-line react/prop-types
                mask="(000) 000 0000"
                error={errors?.phone}
                control={control}
                {...field}
              />
            )}
          />
        </div>
      </fieldset>
    </Card>
  );
};

export default ContactInfoCard;

import { useStoreActions, useStoreState } from "easy-peasy";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import UserAlertInfoBox from "@matter/common/UserAlertInfoBox";
import CoffeeIconForItemHelper from "@matter/util/CoffeeIconForItemHelper";
import HeroTitle from "@matter/common/HeroTitle";
import RadioOption from "@matter/components/options/RadioOptions";
import QuantityChooser from "@matter/components/options/QuantityChooser";
import UniqueIdGenerator from "@matter/util/UniqueIdGenerator";
import { widgetTypes } from "@matter/util/WidgetTypes";
import CheckOption from "@matter/components/options/CheckBoxOptions";
import Button from "@matter/common/Button";

import "react-toastify/dist/ReactToastify.css";

const MenuOptions = () => {
  const { category, itemId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { menu } = useStoreState((state) => state.menu);
  let item = menu.categories[category].items.find(
      (i) => i.menuItemId === parseInt(itemId)
  );
  const itemsLeft = item?.inventory?.quantity;
  let { cart } = useStoreState((state) => state.cart);

  const itemsInCart = cart.filter(i => i.menuItemId === parseInt(itemId));

  const { shopId, shopInfo } = useStoreState((state) => state.shop);
  const { addItem, removeItem } = useStoreActions((actions) => actions.cart);
  const navigate = useNavigate();
  const methods = useForm();
  const imageHelper = new CoffeeIconForItemHelper();
  let [quantity, setQuantity] = useState(1);
  let [orderItem, setOrderItem] = useState({
    name: item.name,
    pickupTime: item.pickupTime,
    priceTotal: 0,
    isComplimentaryRedemption: false,
    isComplimentary: false,
    menuItemId: item.menuItemId,
    isEvent: item.isEvent,
    image: imageHelper.getImageForItemNameFromMenu(item.name, menu),
    isFirstComplimentary: false,
    options: {},
    category: category
  });

  const onDecrease = () => {
    if (quantity > 1) {
      setQuantity((quantity -= 1));
    }
  };

  const onIncrease = () => {
    if(item.inventory !== null) {
      if (quantity + 1 <= item?.inventory?.quantity) {
        setQuantity((quantity += 1));
      }
    } else {
      setQuantity((quantity += 1));
    }
  };

  const onSubmit = () => {
    if (editMode) {
      removeItem(cartItem.id);
    }
    for (let i = 0; i < quantity; i++) {
      let addedItem = Object.assign({}, orderItem);
      addedItem.id = new UniqueIdGenerator().getId();
      addItem(addedItem);
    }
    navigate("../checkout");
  };

  useEffect(() => {
    const subscription = methods.watch(() => {
      updateItem();
    });
    updateItem();
    return () => subscription.unsubscribe();
  }, [shopId, methods.watch]);

  const image = imageHelper.getImageForItemNameFromMenu(item.name, menu);
  let cartItem;
  let editMode = params.get("edit") === "true";
  if (editMode) {
    cartItem = cart.find((item) => item.id === params.get("cartId"));
  }
  const updateItem = async () => {
    let values = methods.getValues();
    let optionKeys = Object.keys(values);
    let newOrderItem = {
      name: item.name,
      pickupTime: item.pickupTime,
      priceTotal: 0,
      isComplimentaryRedemption: false,
      isComplimentary: false,
      menuItemId: item.menuItemId,
      isEvent: item.isEvent,
      image: imageHelper.getImageForItemNameFromMenu(item.name, menu),
      isFirstComplimentary: false,
      options: {},
      category: category
    };
    for (let i = 0; i < optionKeys.length; i++) {
      if (values[optionKeys[i]] !== null && values[optionKeys[i]] !== false) {
        let valueId;
        let optionId;
        if (typeof values[optionKeys[i]] === "string") {
          valueId = parseInt(values[optionKeys[i]]);
          optionId = parseInt(optionKeys[i]);
        } else if (typeof values[optionKeys[i]] === "boolean") {
          optionId = parseInt(optionKeys[i].split(":")[0]);
          valueId = parseInt(optionKeys[i].split(":")[1]);
        }
        const menuOption = item.options.find(
          (o) => o.menuOptionId === optionId
        );
        if (newOrderItem.options[menuOption.title] === undefined) {
          newOrderItem.options[menuOption.title] = {
            price: 0,
            menuOptionId: menuOption.menuOptionId,
            value: []
          };
        }

        let menuOptionValue = menuOption.values.find(
          (v) => v.optionValueId === valueId
        );
        let orderItemOptionValue = {
          isAvailable: menuOptionValue.isAvailable,
          menuOptionId: menuOption.menuOptionId,
          optionValueId: menuOptionValue.optionValueId,
          price: menuOptionValue.price,
          value: menuOptionValue.value,
          priority: menuOptionValue.priority
        };
        newOrderItem.options[menuOption.title].value = [
          orderItemOptionValue,
          ...newOrderItem.options[menuOption.title].value
        ];
        newOrderItem.options[menuOption.title].price += menuOptionValue.price;
        newOrderItem.priceTotal += menuOptionValue.price;
      }
    }

    setOrderItem(newOrderItem);
  };

  const twoCols = item.options.find((o) => o.values.length >= 2) !== undefined;
  return (
    <div className="flex flex-col max-w-[1280px] m-auto pb-[100px] overflow-auto">
      <header className="w-full px-[30px] flex flex-col gap-12 mt-[60px]">
        <div
          className="flex flex-row gap-2 cursor-pointer jus"
          onClick={() => navigate(`/${shopId}`, { replace: true })}>
          <span>
            <ArrowBack
              sx={{
                color: grey[500]
              }}
            />
          </span>
          <span
              className="font-[500] tracking-[.18em] text-[14px] flex-col self-center text-matter-gray-light">
              BACK TO {shopInfo?.shopName?.toUpperCase()}
            </span>
        </div>
      </header>
      <div
        className={
          "flex p-[20px] justify-center items-center sm:items-start flex-col sm:flex-row w-full gap-12"
        }>
        <img
          className={"rounded-md w-[130px] sm:w-[215px] m-[15px]"}
          src={
            image === -1
              ? require("../../util/assets/trr-food-none.png")
              : image
          }
        />
        <div
          className={
            "flex items-center flex-col sm:items-start flex-grow m-[15px] gap-4"
          }>
          {itemsLeft <= 5 && (
            <UserAlertInfoBox>
              <span className="text-matter-yellow-dark text-xs font-[600]">
                Only {itemsLeft} left
              </span>
            </UserAlertInfoBox>
          )}
          <HeroTitle styles="text-xl mb-4 font-[500]">{item.name}</HeroTitle>
          <p className={"text-matter-gray text-center"}>{item.description}</p>
          <FormProvider {...methods}>
            <form className={"mt-5 w-[100%]"} onSubmit={onSubmit}>
              {item.options.map((o) => {
                if (
                  o.widgetType === widgetTypes.ONE_OR_MORE ||
                  o.widgetType === widgetTypes.ALLOW_MULTIPLE
                ) {
                  return (
                    <CheckOption
                      key={o.menuOptionId}
                      option={o}
                      widgetType={o.widgetType}
                      cartItem={cartItem}
                    />
                  );
                } else {
                  return (
                    <RadioOption
                      key={o.menuOptionId}
                      option={o}
                      widgetType={o.widgetType}
                      cartItem={cartItem}
                    />
                  );
                }
              })}
              <QuantityChooser
                total={quantity}
                onDecrease={onDecrease}
                onIncrease={onIncrease}
                twoCols={twoCols}
              />
              <div className={"grid sm:grid-cols-2 gap-[15px] grid-cols-1"}>
                <Button
                  onClick={methods.handleSubmit(onSubmit)}
                  styles={"text-center align-middle font-[500] col-span-2 w-[100%] h-[44px] mt-3"}>
                  {editMode ? "Update item" : "Add to cart"}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default MenuOptions;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { StoreProvider } from "easy-peasy";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";


const root = ReactDOM.createRoot(document.getElementById("root"));

const render = Component => root.render(
    <StoreProvider store={store}>
        <BrowserRouter>
            <Component />
        </BrowserRouter>
    </StoreProvider>
);

render(App)

// if (process.env.NODE_ENV === "development") {
//   if (module.hot) {
//     module.hot.accept("./App", () => {
//       render(App)
//     })
//   }
// }



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

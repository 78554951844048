import { Error } from "@mui/icons-material";
import PropTypes from "prop-types";

const UserAlertInfoBox = ({ children }) => {
  return (
    <div className="flex flex-row gap-1 p-2 justify-center bg-matter-yellow-bg border-none border-r-[3px]">
      <Error className="text-matter-yellow text-[16px]" />
      <div className="flex justify-center items-center">
          {children}
      </div>
    </div>
  );
};

UserAlertInfoBox.propTypes = {
  children: PropTypes.node
};

export default UserAlertInfoBox;
import React, {useState, useEffect} from "react";
import {useStoreState} from "easy-peasy";
import "./nav-style.css";
import {Link, scrollSpy} from "react-scroll";

const Nav = () => {
    const menu = useStoreState(state => state.menu.menu)
    let menuCategories = Object.entries(menu.categories).filter(category => !category[1].membersOnly).sort((a, b) => a[1].index - b[1].index).map(c => c[0])
    const [activeSection, setActiveSection] = useState(menuCategories[0]);
    // Function to handle the activation of a link.
    const handleSetActive = (to) => {
        setActiveSection(to)
        const link = document.getElementById(`${to}-link`);
        link.scrollIntoView({block: "center", inline: "center"})
    };

    useEffect(() => {
        scrollSpy.update();
    }, [activeSection]);


    return (
        <div
            className={"cp-nav flex flex-row overflow-scroll p-5 fixed top-0 left-[0] w-[100%] bg-white z-50 shadow-[0_3px_25px_0px_rgba(0,0,0,0.05)]"}>
            <div className={"mx-auto flex-row flex scroll-smooth"} id={"menuBar"}>
                {menuCategories.map((c, index) => <Link id={`${c}-link`}
                                                        spy={true}
                                                        smooth={true}
                                                        duration={400}
                                                        hashSpy={true}
                                                        offset={-60}
                                                        activeClass={"underline font-bold"}
                                                        onSetActive={handleSetActive}
                                                        className={"cursor-pointer text-matter-green font-[500] whitespace-nowrap mx-[30px] text-[12px] tracking-[2px] leading-[14px]"}
                                                        key={index} to={`${c}`}>{c.toUpperCase()}</Link>)}
            </div>
        </div>
    )
}

export default Nav;

import {useEffect} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";

import {Oval} from "react-loader-spinner";
import Nav from "@matter/components/Nav";
import MenuCategory from "@matter/components/menu/MenuCategory";
import ShopInfoCard from "@matter/common/ShopInfoCard";


const Menu = () => {
    const menu = useStoreState(state => state.menu.menu);
    const {shopId} = useStoreState(state => state.shop)
    const {getMenu} = useStoreActions(actions => actions.menu)
    useEffect(() => {
            getMenu(shopId);
    }, []);
    return (
        <div className={"scroll-smooth"}>
            {(menu === null || menu === undefined) ?
                <div className={"flex justify-center items-center content-center h-[100vh]"}>
                    <Oval
                    height={80}
                    width={80}
                    color="#F7F7F7"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#074330"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                </div>
                :
            <div className={"m-auto"}>
                <Nav/>
                <div className={"flex p-[20px] sm:p-[60px] flex-col-reverse lg:flex-row mt-10"}>
                    <div className={"flex-[1]"}>
                        {Object.entries(menu.categories).sort((a, b) => a[1].index - b[1].index).map(category => {
                            const menuCategory = menu.categories[category[0]];
                            if(!menuCategory.membersOnly) {
                                return (<MenuCategory key={menuCategory.id} category={menuCategory} name={category[0]} />)
                            }
                        })}
                    </div>
                    <ShopInfoCard/>

                </div>

            </div>
            }
        </div>
    );
};

export default Menu;

import { Elements } from "@stripe/react-stripe-js";
import { useLayoutEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { loadStripe } from "@stripe/stripe-js/pure";
import {stripeKey} from "@matter/stripeKey";
import GuestCheckoutPage from "./GuestCheckoutPage";


const index = ({ refs }) => {
  const fetchClientSecret = useStoreActions(
    (actions) => actions.checkout.fetchClientSecret
  );
  const clientSecret = useStoreState((state) => state.checkout.clientSecret);
  useLayoutEffect(() => {
    if (clientSecret === null) {
      fetchClientSecret();
    }
  }, [clientSecret]);

  return (
    <Elements
      ref={refs}
      stripe={loadStripe(stripeKey)}>
      <GuestCheckoutPage clientSecret={clientSecret} />
    </Elements>
  );
};
export default index;

import classNames from "classnames";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { useState } from "react";
import "./modal.css";
import { useFormContext } from "react-hook-form";
import HeroTitle from "@matter/common/HeroTitle";
import TextInput from "@matter/common/Form/TextInput";
import Button from "@matter/common/Button";

const ButtonForCard = ({
  onClick,
  selectedValue,
  label,
  index,
  requireTableNumber
}) => {
  const [showModal, setShowModal] = useState(false);
  const buttonClass = classNames(
    "flex basis-1/2 border rounded-sm flex-col place-items-center p-2",
    {
      "border-gray-300": index !== selectedValue,
      "border-matter-coral": index === selectedValue
    }
  );
  const spanClass = classNames("text-sm font-[500] text-center", {
    "text-matter-gray-dark": index !== selectedValue,
    "text-matter-coral": index === selectedValue
  });

  const {
    register,
    formState: { errors },
    getValues,
    setError
  } = useFormContext();

  return (
    <>
      <button
        className={buttonClass}
        type="button"
        onClick={requireTableNumber ? () => setShowModal(true) : onClick}>
        <span className={spanClass}>{label} {(requireTableNumber && getValues("tableNumber") !== "" && getValues("tableNumber") !== null && getValues("tableNumber") !== undefined) ? "(" + getValues("tableNumber") + ")" : ""}</span>
      </button>
      <ReactModal
        className="modalContent"
        overlayClassName="modalOverlay"
        ariaHideApp={false}
        isOpen={showModal}>
        <div className={"flex flex-col min-w-[300px] min-h-[150px]"}>
          <HeroTitle styles="text-[20px] place-self-start mb-4">
            Add Table #
          </HeroTitle>
          <div className="form-row basis-full md:basis-1/2">
            <TextInput
              error={errors?.tableNumber}
              fieldName="tableNumber"
              register={register}
              placeHolder={"Enter your table #"}
            />
            <Button
              styles={"mt-7"}
              onClick={() => {
                onClick();
                if (
                  getValues("tableNumber") === null ||
                  getValues("tableNumber") === ""
                ) {
                  setError("tableNumber", {
                    type: "custom",
                    message: "Required"
                  });
                } else {
                  setShowModal(false);
                }
              }}>
              Add
            </Button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

ButtonForCard.propTypes = {
  onClick: PropTypes.func,
  selectedValue: PropTypes.number,
  value: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  requireTableNumber: PropTypes.bool
};

export default ButtonForCard;

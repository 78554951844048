import { action } from "easy-peasy";
import { remove } from "lodash";
const cartModel = {
    cart: [],
    addItem: action((state, item) => {
        state.cart = [item, ...state.cart];
    }),
    clearCart: action((state, item) => {
        state.cart = [];
    }),
    removeItem: action((state, itemId) => {
        let cart = [...state.cart];
        remove(cart, (i) => i.id === itemId);
        state.cart = [...cart];
    }),
}

export default cartModel;

import PropTypes from "prop-types";
import classNames from "classnames";

const Card = ({ children, styles }) => {
    const classes = classNames({
        [`${styles}`]: !!styles
    }, "h-fit p-2 shadow-none md:shadow-md bg-white rounded-[8px] p-[20px]")

    return <div className={classes}>{children}</div>
};

Card.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.string,
}

Card.defaultProps = {
    styles: ""
}

export default Card;

import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { widgetTypes } from "@matter/util/WidgetTypes";
import HeroTitle from "@matter/common/HeroTitle";

const RadioOption = ({ widgetType, option, cartItem }) => {
  const { register, setValue } = useFormContext();
  return (
    <>
        <div className={"my-6"}>
          <HeroTitle styles="place-self-start text-lg font-[500]">
            {option.title}
          </HeroTitle>
        </div>
        <div className={"grid sm:grid-cols-2 gap-[15px] grid-cols-1"}>
        {option.values.filter(value => value.isAvailable).map((v, index) => (
          <div
            className={
              "flex justify-start border-solid rounded border-[1px] cursor-pointer pl-3 w-[100%] h-[90px] border-matter-gray-border items-center"
            }
            key={v.optionValueId}
          onClick={() => setValue(option.menuOptionId.toString(), v.optionValueId.toString())}>
            <input
              className={"text-matter-coral active:text-matter-gray-light"}
              {...register(option.menuOptionId.toString(), {
                required: widgetType === widgetTypes.EXACTLY_ONE
              })}
              defaultChecked={(index === 0 && widgetType === widgetTypes.EXACTLY_ONE) || option.values.length === 1 || cartItem !== undefined && cartItem.options[option.title] !== undefined && cartItem.options[option.title].value.find(value => v.optionValueId === value.optionValueId) !== undefined}
              type="radio"
              name={option.menuOptionId.toString()}
              id={v.value}
              value={v.optionValueId}
            />
            <div className={"flex flex-col align-middle ml-4"}>
              <label
                className="place-self-start  text-matter-gray-light font-medium mb-0"
                htmlFor={v.value}>
                {v.value}
              </label>
              <span className={"text-sm font-[500]"}>${v.price.toFixed(2)}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

RadioOption.propTypes = {
  widgetType: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
    cartItem: PropTypes.object
};

export default RadioOption;

export default class CoffeeIconForItemHelper {
    constructor() {
        const imgBasePath = "./assets/";

        this.images = [
            {key: "espresso.png", image: require(imgBasePath + "espresso.png")},
            {
                key: "americano.png",
                image: require(imgBasePath + "Americano.png"),
            },
            {
                key: "traditional-macchiato.png",
                image: require(imgBasePath + "traditional-macchiato.png"),
            },
            {
                key: "cappuaccino.png",
                image: require(imgBasePath + "cappuaccino.png"),
            },
            {key: "latte.png", image: require(imgBasePath + "latte.png")},
            {key: "mocha.png", image: require(imgBasePath + "mocha.png")},
            {
                key: "cold-latte.png",
                image: require(imgBasePath + "cold-latte.png"),
            },
            {
                key: "cold-mocha.png",
                image: require(imgBasePath + "cold-mocha.png"),
            },
            {key: "drip.png", image: require(imgBasePath + "drip.png")},
            {key: "pourover.png", image: require(imgBasePath + "pourover.png")},
            {
                key: "frenchpress_for_2.png",
                image: require(imgBasePath + "frenchpress_for_2.png"),
            },
            {key: "chemex.png", image: require(imgBasePath + "chemex.png")},
            {
                key: "chemex_for_2.png",
                image: require(imgBasePath + "chemex_for_2.png"),
            },
            {
                key: "cold-brewed-iced-coffee.png",
                image: require(imgBasePath + "cold-brewed-iced-coffee.png"),
            },
            {
                key: "hot-chocolate.png",
                image: require(imgBasePath + "hot-chocolate.png"),
            },
            {key: "steamer.png", image: require(imgBasePath + "steamer.png")},
            {key: "tea.png", image: require(imgBasePath + "tea.png")},
            {key: "cold-tea.png", image: require(imgBasePath + "cold-tea.png")},
            {
                key: "cold-americano.png",
                image: require(imgBasePath + "cold-americano.png"),
            },
            {
                key: "cold-latte.png",
                image: require(imgBasePath + "cold-latte.png"),
            },
            {
                key: "cold-steamer.png",
                image: require(imgBasePath + "cold-steamer.png"),
            },
            {
                key: "macchiato.png",
                image: require(imgBasePath + "macchiato.png"),
            },
            {
                key: "cold-macchiato.png",
                image: require(imgBasePath + "cold-macchiato.png"),
            },
            {key: "cortado.png", image: require(imgBasePath + "cortado.png")},
            {key: "smoothie.png", image: require(imgBasePath + "smoothie.png")},

            {
                key: "blue-majik.png",
                image: require(imgBasePath + "blue-majik.png"),
            },
            {
                key: "coffee-beans.png",
                image: require(imgBasePath + "coffee-beans.png"),
            },
            {
                key: "bottle-blue.png",
                image: require(imgBasePath + "bottle-blue.png"),
            },
            {
                key: "cold-blue-majik.png",
                image: require(imgBasePath + "cold-blue-majik.png"),
            },
            {
                key: "tea-berry.png",
                image: require(imgBasePath + "tea-berry.png"),
            },
            {
                key: "cold-tea-berry.png",
                image: require(imgBasePath + "cold-tea-berry.png"),
            },
            {
                key: "tea-ginger.png",
                image: require(imgBasePath + "tea-ginger.png"),
            },
            {
                key: "cold-tea-ginger.png",
                image: require(imgBasePath + "cold-tea-ginger.png"),
            },
            {
                key: "smoothie_blue.png",
                image: require(imgBasePath + "smoothie_blue.png"),
            },
            {
                key: "smoothie_ginger.png",
                image: require(imgBasePath + "smoothie_ginger.png"),
            },

            {
                key: "TRR-drink-juice.png",
                image: require(imgBasePath + "TRR-drink-juice.png"),
            },
            {
                key: "TRR-drink-latte.png",
                image: require(imgBasePath + "TRR-drink-latte.png"),
            },
            {
                key: "TRR-drink-cold-brew.png",
                image: require(imgBasePath + "TRR-drink-cold-brew.png"),
            },
            {
                key: "TRR-drink-espresso.png",
                image: require(imgBasePath + "TRR-drink-espresso.png"),
            },
            {
                key: "TRR-drink-americano.png",
                image: require(imgBasePath + "TRR-drink-americano.png"),
            },
            {
                key: "TRR-drink-matcha-blossom.png",
                image: require(imgBasePath + "TRR-drink-matcha-blossom.png"),
            },
            {
                key: "TRR-drink-cereal-milk-latte.png",
                image: require(imgBasePath + "TRR-drink-cereal-milk-latte.png"),
            },
            {
                key: "TRR-drink-salted-cream-coffee.png",
                image: require(imgBasePath +
                    "TRR-drink-salted-cream-coffee.png"),
            },

            {
                key: "trr-drink-coffee-americano.png",
                image: require(imgBasePath + "trr-drink-coffee-americano.png"),
            },
            {
                key: "trr-drink-coffee-freshbrewed.png",
                image: require(imgBasePath +
                    "trr-drink-coffee-freshbrewed.png"),
            },
            {
                key: "trr-drink-coffee-latte.png",
                image: require(imgBasePath + "trr-drink-coffee-latte.png"),
            },
            {
                key: "trr-drink-coffee-icedcoffee.png",
                image: require(imgBasePath + "trr-drink-coffee-icedcoffee.png"),
            },
            {
                key: "trr-drink-coffee-coldbrew.png",
                image: require(imgBasePath + "trr-drink-coffee-coldbrew.png"),
            },
            {
                key: "trr-drink-coffee-espresso.png",
                image: require(imgBasePath + "trr-drink-coffee-espresso.png"),
            },
            {
                key: "trr-drink-specialty-cerealmilk.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-cerealmilk.png"),
            },
            {
                key: "trr-drink-specialty-electric.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-electric.png"),
            },
            {
                key: "trr-drink-specialty-matchablossom.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-matchablossom.png"),
            },
            {
                key: "trr-drink-specialty-robinsonlatte.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-robinsonlatte.png"),
            },
            {
                key: "trr-drink-specialty-rosegold.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-rosegold.png"),
            },
            {
                key: "trr-drink-specialty-saltedcream.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-saltedcream.png"),
            },
            {
                key: "trr-drink-specialty-taro.png",
                image: require(imgBasePath + "trr-drink-specialty-taro.png"),
            },
            {
                key: "trr-drink-coffeecocktail-carajillo.png",
                image: require(imgBasePath +
                    "trr-drink-coffeecocktail-carajillo.png"),
            },
            {
                key: "trr-drink-coffeecocktail-elevatedcoffeetonic.png",
                image: require(imgBasePath +
                    "trr-drink-coffeecocktail-elevatedcoffeetonic.png"),
            },
            {
                key: "trr-drink-coffeecocktail-espressomartini.png",
                image: require(imgBasePath +
                    "trr-drink-coffeecocktail-espressomartini.png"),
            },
            {
                key: "trr-drink-coffeecocktail-oldfashioned.png",
                image: require(imgBasePath +
                    "trr-drink-coffeecocktail-oldfashioned.png"),
            },
            {
                key: "trr-drink-juicecocktail-blueberrybasil.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-blueberrybasil.png"),
            },
            {
                key: "trr-drink-juicecocktail-bourbonlemonadee.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-bourbonlemonadee.png"),
            },
            {
                key: "trr-drink-juicecocktail-orangehorizon.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-orangehorizon.png"),
            },
            {
                key: "trr-drink-juicecocktail-watermelonfalls.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-watermelonfalls.png"),
            },
            {
                key: "trr-drink-juicecocktail-watermelonrose.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-watermelonrose.png"),
            },
            {
                key: "trr-drink-wine-rose.png",
                image: require(imgBasePath + "trr-drink-wine-rose.png"),
            },
            {
                key: "trr-drink-wine-sparkling.png",
                image: require(imgBasePath + "trr-drink-wine-sparkling.png"),
            },
            {
                key: "trr-drink-wine-red.png",
                image: require(imgBasePath + "trr-drink-wine-red.png"),
            },
            {
                key: "trr-drink-wine-white.png",
                image: require(imgBasePath + "trr-drink-wine-white.png"),
            },
            {
                key: "trr-drink-bottle-crookedstaveipa.png",
                image: require(imgBasePath +
                    "trr-drink-bottle-crookedstaveipa.png"),
            },
            {
                key: "trr-drink-bottle-crookedstavevon.png",
                image: require(imgBasePath +
                    "trr-drink-bottle-crookedstavevon.png"),
            },
            {
                key: "trr-drink-bottle-redstripe.png",
                image: require(imgBasePath + "trr-drink-bottle-redstripe.png"),
            },
            {
                key: "trr-drink-bottle-stella.png",
                image: require(imgBasePath + "trr-drink-bottle-stella.png"),
            },
            {
                key: "trr-drink-default.png",
                image: require(imgBasePath + "trr-drink-default.png"),
            },
            {
                key: "trr-drink-none.png",
                image: require(imgBasePath + "trr-drink-none.png"),
            },

            {
                key: "trr-drink-tea-assorted.png",
                image: require(imgBasePath + "trr-drink-tea-assorted.png"),
            },
            {
                key: "trr-drink-specialty-rosegoldlatte.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-rosegoldlatte.png"),
            },
            {
                key: "trr-drink-specialty-recharge.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-recharge.png"),
            },
            {
                key: "trr-drink-specialty-immunity.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-immunity.png"),
            },
            {
                key: "trr-drink-specialty-healthshot.png",
                image: require(imgBasePath +
                    "trr-drink-specialty-healthshot.png"),
            },
            {
                key: "trr-drink-specialty-detox.png",
                image: require(imgBasePath + "trr-drink-specialty-detox.png"),
            },
            {
                key: "trr-drink-juicecocktail-strawbantini.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-strawbantini.png"),
            },
            {
                key: "trr-drink-juicecocktail-matchahaven.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-matchahaven.png"),
            },
            {
                key: "trr-drink-juicecocktail-berrycherry.png",
                image: require(imgBasePath +
                    "trr-drink-juicecocktail-berrycherry.png"),
            },
            {
                key: "trr-drink-coffeecocktail-coffeetonic.png",
                image: require(imgBasePath +
                    "trr-drink-coffeecocktail-coffeetonic.png"),
            },
            {
                key: "trr-drink-coffee-oatdraftlatte.png",
                image: require(imgBasePath +
                    "trr-drink-coffee-oatdraftlatte.png"),
            },
            {
                key: "trr-drink-coffee-machiato.png",
                image: require(imgBasePath + "trr-drink-coffee-machiato.png"),
            },
            {
                key: "trr-drink-coffee-cortado.png",
                image: require(imgBasePath + "trr-drink-coffee-cortado.png"),
            },
            {
                key: "trr-drink-coffee-cappuccino.png",
                image: require(imgBasePath + "trr-drink-coffee-cappuccino.png"),
            },
            {
                key: "trr-drink-coffee-batchcoffee.png",
                image: require(imgBasePath +
                    "trr-drink-coffee-batchcoffee.png"),
            },

            {
                key: "trr-event-default.png",
                image: require(imgBasePath + "trr-event-default.png"),
            },
            {
                key: "trr-event-brunch.png",
                image: require(imgBasePath + "trr-event-brunch.png"),
            },
            {
                key: "trr-event-mixology.png",
                image: require(imgBasePath + "trr-event-mixology.png"),
            },
            {
                key: "trr-event-coffee.png",
                image: require(imgBasePath + "trr-event-coffee.png"),
            },
            {
                key: "trr-event-dance.png",
                image: require(imgBasePath + "trr-event-dance.png"),
            },
            {
                key: "trr-event-education.png",
                image: require(imgBasePath + "trr-event-education.png"),
            },
            {
                key: "trr-event-happyhour.png",
                image: require(imgBasePath + "trr-event-happyhour.png"),
            },
            {
                key: "trr-event-music-dj.png",
                image: require(imgBasePath + "trr-event-music-dj.png"),
            },
            {
                key: "trr-event-music-live.png",
                image: require(imgBasePath + "trr-event-music-live.png"),
            },
            {
                key: "trr-event-music-mic.png",
                image: require(imgBasePath + "trr-event-music-mic.png"),
            },
            {
                key: "trr-event-bodypainting.png",
                image: require(imgBasePath + "trr-event-bodypainting.png"),
            },
            {
                key: "trr-event-paint.png",
                image: require(imgBasePath + "trr-event-paint.png"),
            },
            {
                key: "trr-event-party.png",
                image: require(imgBasePath + "trr-event-party.png"),
            },
            {
                key: "trr-event-trivia.png",
                image: require(imgBasePath + "trr-event-trivia.png"),
            },
            {
                key: "trr-event-wine.png",
                image: require(imgBasePath + "trr-event-wine.png"),
            },
            {
                key: "trr-event-yoga.png",
                image: require(imgBasePath + "trr-event-yoga.png"),
            },

            {
                key: "avocado-toast.png",
                image: require(imgBasePath + "AvocadoToast.png"),
            },
            {
                key: "arugula-salad.png",
                image: require(imgBasePath + "61ArugulaSalad.png"),
            },
            {
                key: "bang-bang.png",
                image: require(imgBasePath + "BangBang.png"),
            },
            {
                key: "chicken-sandwich.png",
                image: require(imgBasePath + "ChickenSandwich.png"),
            },
            {
                key: "black-bean-burger.png",
                image: require(imgBasePath + "BlackBeanBurger.png"),
            },
            {
                key: "egg-and-cheese-sandwich.png",
                image: require(imgBasePath + "EggAndCheeseSandwich.png"),
            },
            {
                key: "chickpea.png",
                image: require(imgBasePath + "Chickpea.png"),
            },
            {
                key: "mongolian.png",
                image: require(imgBasePath + "Mongolian.png"),
            },
            {
                key: "southwest.png",
                image: require(imgBasePath + "Southwest.png"),
            },
            {
                key: "super-nova.png",
                image: require(imgBasePath + "SuperNova.png"),
            },
            {
                key: "spicy-chicken-sandwich.png",
                image: require(imgBasePath + "SpicyChickenSandwich.png"),
            },
            {
                key: "truffle-fries.png",
                image: require(imgBasePath + "TruffleFries.png"),
            },
            {
                key: "turkey-avocado-sandwitch.png",
                image: require(imgBasePath + "TurkeyAvocadoSandwitch.png"),
            },

            {
                key: "trr-food-smallplate-bangbang.png",
                image: require(imgBasePath +
                    "trr-food-smallplate-bangbang.png"),
            },
            {
                key: "trr-food-smallplate-brussellsprouts.png",
                image: require(imgBasePath +
                    "trr-food-smallplate-brussellsprouts.png"),
            },
            {
                key: "trr-food-smallplate-fries.png",
                image: require(imgBasePath +
                    "trr-food-smallplate-fries.png"),
            },
            {
                key: "trr-food-smallplate-chickpea.png",
                image: require(imgBasePath +
                    "trr-food-smallplate-chickpea.png"),
            },
            {
                key: "trr-food-smallplate-parfait.png",
                image: require(imgBasePath +
                    "trr-food-smallplate-parfait.png"),
            },
            {
                key: "trr-food-smallplate-pudding.png",
                image: require(imgBasePath +
                    "trr-food-smallplate-pudding.png"),
            },
            {
                key: "trr-food-toast-avocado.png",
                image: require(imgBasePath + "trr-food-toast-avocado.png"),
            },
            {
                key: "trr-food-toast-supernova.png",
                image: require(imgBasePath +
                    "trr-food-toast-supernova.png"),
            },
            {
                key: "trr-food-worb_chicken.png",
                image: require(imgBasePath + "trr-food-worb_chicken.png"),
            },
            {
                key: "trr-food-worb_mongolian.png",
                image: require(imgBasePath + "trr-food-worb_mongolian.png"),
            },
            {
                key: "trr-food-worb_southwest.png",
                image: require(imgBasePath + "trr-food-worb_southwest.png"),
            },
            {
                key: "trr-food-handheld-blackbean.png",
                image: require(imgBasePath +
                    "trr-food-handheld-blackbean.png"),
            },
            {
                key: "trr-food-handheld-chickenbreakfast.png",
                image: require(imgBasePath +
                    "trr-food-handheld-chickenbreakfast.png"),
            },
            {
                key: "trr-food-handheld-classic.png",
                image: require(imgBasePath +
                    "trr-food-handheld-classic.png"),
            },
            {
                key: "trr-food-handheld-crispychicken.png",
                image: require(imgBasePath +
                    "trr-food-handheld-crispychicken.png"),
            },
            {
                key: "trr-food-handheld-spicycrispychicken.png",
                image: require(imgBasePath +
                    "trr-food-handheld-spicycrispychicken.png"),
            },
            {
                key: "trr-food-handheld-eggandcheese.png",
                image: require(imgBasePath +
                    "trr-food-handheld-eggandcheese.png"),
            },
            {
                key: "trr-food-handheld-tunasandwich.png",
                image: require(imgBasePath +
                    "trr-food-handheld-tunasandwich.png"),
            },
            {
                key: "trr-food-handheld-turkeyavocado.png",
                image: require(imgBasePath +
                    "trr-food-handheld-turkeyavocado.png"),
            },
            {
                key: "trr-food-salad-arugula.png",
                image: require(imgBasePath + "trr-food-salad-arugula.png"),
            },
            {
                key: "trr-food-salad-kale.png",
                image: require(imgBasePath + "trr-food-salad-kale.png"),
            },

            {
                key: "trr-food-none.png",
                image: require(imgBasePath + "trr-food-none.png"),
            },
        ];
    }

    getImageForFileName(name) {
        if(name.includes("https://")) {
            return name;
        } else {
            for (var k = 0; k < this.images.length; k++) {
                const image = this.images[k];
                if (image.key === name) {
                    return image.image;
                }
            }
        }


        // This is for where food item has no image. Drink item of no image handled from backend
        let obj = this.images[this.images.length - 1];
        return obj.image;
    }

    getImageForItemNameFromMenu(nameToGrab, menu) {
        if (menu && menu.categories) {
            var catHeaders = Object.keys(menu.categories);
            for (let category of catHeaders) {
                for (let item of menu.categories[category].items) {
                    if (item.name === nameToGrab) {
                        return this.getImageForFileName(item.image);
                    }
                }
            }
        }
        return -1;
    }
}

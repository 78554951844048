import {useNavigate, useParams} from "react-router-dom";
import {useStoreActions, useStoreState} from "easy-peasy";
import {useEffect} from "react";
import {Oval} from "react-loader-spinner";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {grey} from "@mui/material/colors";
import getPickupTime from "@matter/util/PickupTimeHelper";
import check from "../../util/assets/check.svg";
import appStore from "../../util/assets/appstore.svg";
import googlePlay from "../../util/assets/googleplay.svg";


const OrderComplete = () => {
    const {orderId} = useParams();
    const getOrder = useStoreActions((actions) => actions.order.getOrder);
    const {shopInfo, membershipInfo} = useStoreState(state => state.shop)
    const {getMembershipInfo, setMembershipInfo} = useStoreActions(actions => actions.shop)
    const {order} = useStoreState((state) => state.order);
    const navigate = useNavigate();
    useEffect(() => {
        if (orderId !== undefined) {
            setMembershipInfo(null);
            getOrder(orderId);
        }
        if (shopInfo.membershipId !== null && shopInfo.membershipId !== undefined) {
            getMembershipInfo(shopInfo.membershipId);
        }

    }, []);
    const pickupTimes = (order !== undefined && order !== null) ? getPickupTime(order.items) : null;
    return (
        <div className={"flex flex-col justify-center items-center p-8"}>
            {(order === null || order === undefined) ?
                <div className={"flex justify-center items-center content-center h-[100vh]"}>
                    <Oval
                        height={80}
                        width={80}
                        color="#F7F7F7"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#074330"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
                :
                <>
                    <div className={"text-left w-[100%]"}>
                        <div
                            className="flex flex-row gap-2 cursor-pointer"
                            onClick={() => {
                                navigate(`/${shopInfo?.shopId}`, {replace: true});
                            }}>
            <span>
              <ArrowBack
                  sx={{
                      color: grey[500]
                  }}
              />
            </span>
                            <span
                                className="font-default tracking-[.18em] text-[14px] flex-col self-center underline text-matter-gray-light">
              BACK TO {shopInfo?.shopName?.toUpperCase()}
            </span>
                        </div>
                    </div>
                    <div className={"max-w-[375px] sm:max-w-[430px] flex flex-col items-center mt-24 mb-8"}>
                        <img src={check} width={25} height={25}/>
                        <span className={"font-bold text-[30px] mt-3"}>ORDER CONFIRMED</span>
                        <span className={"text-matter-gray font-medium text-lg mt-2"}>READY IN {pickupTimes.minTime} - {pickupTimes.maxTime} mins</span>
                        <span
                            className={"text-matter-gray font-medium text-lg mt-2"}>Confirmation number: {order.orderId}</span>
                    </div>
                    <div
                        className={"w-[375px] sm:w-[430px] flex p-[20px] flex-col mt-4 shadow-[0_3px_25px_0px_rgba(0,0,0,0.05)] rounded-[8px] border-[1px] border-matter-gray-lightest items-center"}>
                        <span
                            className={"text-matter-gray-dark tracking-[.1em] text-sm font-semibold"}>ORDER DETAILS</span>
                        {order.items.map((item, index) =>
                            <div className={"flex flex-col items-center"} key={index}>
                                <span className={"text-matter-gray-dark text-sm font-semibold mt-3"}>{item.name}</span>
                                <div className={"flex"}>
                                    {item.options.map((o, index) => <span key={index}
                                                                          className={"mt-1 text-matter-gray-light text-sm mr-2"}>{o.value}{item.options.length > 1 && index !== item.options.length - 1 ? ", " : ""}</span>)}
                                </div>
                                <span className={"mt-3 text-matter-gray-dark font-bold"}>${item.price.toFixed(2)}</span>
                            </div>
                        )}

                    </div>
                    <div
                        className={"w-[375px] sm:w-[430px] rounded-[8px] flex flex-col bg-matter-gray-lightest py-6 px-9 mt-3"}>
                        {membershipInfo !== null &&
                            <span className={"text-matter-gray text-xs font-semibold text-center"}>Not a member?</span>}
                        <span
                            className={"text-matter-gray-dark font-semibold mt-3"}>{membershipInfo !== null ? "Download the app and sign up for perks!" : "Download the app for rewards"}</span>
                        {membershipInfo !== null &&
                            <ul className={"list-disc text-matter-gray-light mt-2"}>
                                {membershipInfo.memberBenefits.map((b, i) => <li className={"mt-1"}
                                                                                 key={i}>{b.title}</li>)}
                            </ul>}
                        <div className={"flex flex-col items-center md:justify-between mt-3 md:flex-row"}>
                            <img className={"cursor-pointer"}
                                 onClick={() => window.open(shopInfo.shopId === 115 ? "https://apps.apple.com/us/app/robinson-room/id1543270230" : "https://apps.apple.com/us/app/coffeepass-order-ahead-coffee/id1596107600", "_blank")}
                                 src={appStore} width={175}/>
                            <img className={"cursor-pointer"}
                                 onClick={() => window.open(shopInfo.shopId === 115 ? "https://play.google.com/store/apps/details?id=com.robinson&hl=en_US&gl=US" : "https://play.google.com/store/apps/details?id=com.coffeeapp&hl=en_US&gl=US", "_blank")}
                                 src={googlePlay} width={175}/>
                        </div>
                    </div>
                </>}
        </div>
    );
};

export default OrderComplete;

import { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Oval } from "react-loader-spinner";
import Card from "@matter/common/Card";
import HeroTitle from "@matter/common/HeroTitle";
import Button from "@matter/common/Button";
import TextButton from "@matter/common/TextButton";
import OrderItem from "./components/OrderItem";
import CostItem from "./components/CostItem";

const OrderSummaryCard = ({
  costObject,
  costTotal,
  cart,
  loading,
  termsOfService
}) => {
  const { control, getValues } = useFormContext();
  const hasNonEventItemsInCart = cart.some((item) => !item.isEvent);
  const { shopId } = useStoreState((state) => state.shop);
  const navigate = useNavigate();
  const { setFormValues } = useStoreActions((actions) => actions.checkout);
  const { isValid } = useFormState({ control });

  if (!hasNonEventItemsInCart) {
    delete costObject.Tip;
  }

  const costEntries = Object.entries(costObject);

  const ItemizedList = isEmpty(cart)
    ? null
    : cart.map((item, index) => (
        <OrderItem key={index} item={item} isFromCheckout={true} />
      ));

  const CostList = isEmpty(cart)
    ? null
    : costEntries.map(([label, value], index) => (
        <CostItem
          key={`${label}-${value}-${index}`}
          label={label}
          value={value}
        />
      ));

  return (
    <Card styles="flex w-100 md:w-[375px] flex-col gap-4 p-4 divide-y divide-slate-100 sticky top-3">
      <div className="flex flex-row justify-between">
        <HeroTitle styles="text-[20px] font-[500] place-self-start">Order summary</HeroTitle>
        <TextButton
          onClick={() => {
            setFormValues(getValues());
            return navigate(`/${shopId}/?addToCart=true`, {
              replace: true
            });
          }}
          styles="!justify-between !gap-1">
          <AddIcon
            style={{ color: "#D3AE54", fontSize: "10px", placeSelf: "center" }}
          />
          <span className="text-[13px] font-[500] place-self-center">Add Item</span>
        </TextButton>
      </div>
      <div className="flex flex-col gap-2">{ItemizedList}</div>
      <div className="flex flex-col gap-2">{CostList}</div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row justify-between first:mt-4">
          <strong className="text-[17px] font-[500] uppercase">total</strong>
          <strong className="text-[17px] font-[500]">${Number(costTotal).toFixed(2)}</strong>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between gap-4">
            <p className="text-matter-gray-light w-auto mb-[10px] text-[13px] font-[400] break-words text-left">
              {termsOfService}
            </p>
          </div>
          <Button
            disabled={loading || !isValid}
            styles="h-[44px] text-sm font-[500] items-center"
            type="submit">
            {loading ? (
              <Oval
                height={15}
                width={15}
                color="#F7F7F7"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#074330"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Place order"
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
};

OrderSummaryCard.propTypes = {
  costObject: PropTypes.object.isRequired,
  costTotal: PropTypes.number.isRequired,
  termsOfService: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

OrderSummaryCard.defaultProps = {
  loading: false
};

export default OrderSummaryCard;
